import React from "react";
import { useTranslation } from "react-i18next";
import { HomeSidebar } from "./Sidebar";

import bannerImg from "../../media/game/newbanner.png";
import bannerImg_h from "../../media/game/newbanner_h.png"
import bannerImg_w from "../../media/game/newbanner_w.png"
import bannerImg_e from "../../media/game/newbanner_e.png"
import motionTwinImg from "../../media/game/motiontwin.gif";

type Props = {
  children: React.ReactNode;
  sidebar?: React.ReactNode;
};

export default function Layout({ children, sidebar }: Props) {
  const { t } = useTranslation();
  if (!sidebar) {
    sidebar = <HomeSidebar />;
  }

  return (
    <div className="Game">
      <div className="GameFrame">
        <img
          alt=""
          className="bannerContour"
          src={bannerImg_h}
          width="765"
          height="101"
        />
        <div className="headerLimit" />
        <section className="flexDivs">
          {sidebar}
          <main className="contenuPrincipal">{children}</main>
        </section>
      </div>
    </div>
  );
}
